.app-main {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.rankings {
  font-size: 18px;
  background-color: whitesmoke;
  width: 90%;
  margin: 20px;
  padding-bottom: 20px;
}
.rankings-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 15px;
}
.picks {
  font-size: 14px;
  padding-left: 10px;
}
.player-img-rankings {
  border: solid 1px #282c34;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden;
}
.individual-picks{
  display: flex;
  align-items: center;
  padding: 5px;
}

.prize-money {
  color: white;
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 30px;
  min-width: 300px;
}
.prize-money-header {
  text-align: center;
  font-size: 20px;
}
.prize-money-header-total {
  text-align: center;
  font-size: 16px;
  padding-bottom: 20px;
}
.player-breakdown {
  color: white;
  font-size: 18px;
  font-weight: bold;
  padding-top: 20px;
  padding-bottom: 20px;
}
.player-breakdown-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 10px;
}
.player-breakdown-score{
  width: 35px;
  background-color: white;
  color: #282c34;
  text-align: center;
}
.player-img-wrapper {
  border: solid 1px white;
  width: 40px;
  border-radius: 50%;
  overflow: hidden;
}
.player-img {
  width: 40px;
}
.cut {
  text-decoration: line-through;
}